
a, .btn-link {
    text-decoration: none;
}

.pointer {
    cursor: pointer;
}
 
// Here you can add other styles
.simplebar-content {
    .nav-item {
        color: white;
        .nav-link {
            color: white;
        }
    }
    .nav-link.nav-group-toggle {
        color: white;
    }

    .nav-item, .nav-group, .nav-item:hover, .nav-group:hover, { 
        min-height: 44.63px;
 

        a.nav-link {
            min-height: 44.63px;
            svg {
                margin-right: 10px;
            }
            span{ 
                font-weight: 600;
            }
        }

    }

    .nav-item:hover, .nav-group:hover { 
        min-height: 44.63px; 

    }
}


.navFontAwesomeIcon {
    // margin: 0 20px 0 10px;
    width: 2rem; 
    text-align: left;
}

.header {
    background: #69727a;
    
    .header-toggler, .header-nav .nav-link {
        background-color: transparent;
        color: #ffffff;
    }
 
}

.sidebar.sidebar-fixed {
    .nav-item:hover, .nav-group:hover {
        width: 16rem; 

        .nav-link {
            color: #fff;
            background: rgb(58, 164, 221) !important;
            border-left-color: #000000;

            .navFontAwesomeIcon {
                margin-right: 10px;
            } 
            span {
                display: inline-block;
            }
        }

        .nav-group-items {
            // display: block;
            // height: auto !important;  
            // padding: 0;
            // margin: 0;
            // visibility: visible; 
            // opacity: 1;
            // position: absolute;
            // z-index: 2;
            // width: 12rem;
            // right: 0;

            .nav-item {
                width: 100%;
                background-color: #4797c2;

                &:hover {
                    background: #3aa4dd;
                }
                .nav-link {
                    background: unset !important;
                    // padding-left: 1.5rem;
                }


            }

            
        }
    }
}

.sidebar-narrow {
    
    .sidebar-nav, .simplebar-content-wrapper, 
    .sidebar-nav, .simplebar-wrapper, 
    .simplebar-mask, .simplebar-offset, 
    .simplebar-content-wrapper {
        overflow: unset !important;
    }
    .nav-item, .nav-group {
        .nav-link {
            .navFontAwesomeIcon {
                margin-right: 0px;
            } 
            span {
                display: none;
            }
            &::after {
                display: none;
            }
        }
    }
 
    .nav-item:hover, .nav-group:hover {
        width: 16rem; 

        .nav-link {
            color: #fff;
            background: rgb(58, 164, 221) !important;
            border-left-color: #000000;

            .navFontAwesomeIcon {
                margin-right: 10px;
            } 
            span {
                display: inline-block;
                margin-left: 1rem;
            }
        }

        .nav-group-items {
            display: block;
            height: auto !important;  
            padding: 0;
            margin: 0;
            visibility: visible; 
            opacity: 1;
            position: absolute;
            z-index: 2;
            width: 12rem;
            right: 0;

            .nav-item {
                width: 100%;
                background-color: #4797c2;

                &:hover {
                    background: #3aa4dd;
                }
                .nav-link {
                    background: unset !important;
                    padding-left: 1rem;
                    span {
                        margin-left: unset;
                    }
                }


            }

            
        }
    }
 
}
 
.topWalkmeButton {
    border: 2px solid #efb852;
    // margin: 10px;
    color: #efb852 !important;
    font-weight: 500;
    text-align: center !important;
    padding: 3px 18px !important; 
    display: flex;
    justify-content: center;
    border-radius: 5px;
}

.footer_links {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    height: 100%;
    a {
        margin: 0 5px;
    }
}





.datatableContainer { 
    padding: 0;
}

.searchBarContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1.5rem;

    @media (min-width:768px) {
        justify-content: flex-end;
    }
}
.titleContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1rem;

    @media (min-width:768px) {
        justify-content: flex-start;
    }
}

.tableContainer > .react-bootstrap-table > .table-striped > tr > th {
    background: cornflowerblue !important; 
}

 
// .table {
//     --cui-table-color: var(--cui-body-color);
//     // --cui-table-bg: transparent;
//     --cui-table-border-color: #f4f4f4;  
//     // --cui-table-striped-bg: #f9f9f9; 
// }

.tableContainer {
    .react-bootstrap-table { 
        .table-striped {
            thead {
                tr {
                    background: #ECF0F5 ;
                    th {
                        border-color: #f4f4f4; 
                    }
                    th.sortable { 
                        cursor: pointer;
                    }
                }
            }
            tbody {
                tr:nth-of-type(odd) {
                    // --cui-table-accent-bg: var(--cui-table-striped-bg);
                    // background: #f9f9f9 ;   
                }
                tr {
                     
                    td {  
                        // background: transparent !important; 
                        border-color: #f4f4f4; 
                    } 
                }
            }
        }
    }

    #pageDropDown {
        margin-right: 1rem;
        padding: 0.3rem 0.65rem !important;
        background: #fff; 
    }

    .react-bs-table-sizePerPage-dropdown {
        a { 
            width: 100%;
            height: 100%;
            display: block;
            text-decoration: none;

        }
    }

    .react-bootstrap-table-pagination-list {
        display: flex;
        justify-content: flex-end;
 
    }
}

*, body, table, tr, td, th, h1, h2, h3, h4, h5, h6, p, span, div {
    font-family: 'Source Sans Pro', sans-serif;
}

.form-label {
    font-weight: 700;
}
.form-control, .form-select {
    border-color: #d2d6de;
}
 

.blink_me {
  animation: blinker 1s linear infinite;
} 
@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.input_tooltips {
    display:flex; 
    // background-color: #ebebeb;
    border-radius:0.375rem;
}

.input_exclamation_wrapper { 
    display: flex;
    align-items: center;
    padding: 5px;
    font-size: 1.2rem
}



.react-autosuggest__container {
    position: relative;
  }
  
  .react-autosuggest__input {
    width: 240px;
    height: 30px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
  }
  
  .react-autosuggest__input--focused {
    outline: none;
  }
  
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .react-autosuggest__suggestions-container {
    display: none;
  }
  
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    width: 280px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }

.table_sticky_header  {
    // background: #007bff; /* set background color for the overcome transparent header cell */
    // color: #fff;
        position: -webkit-sticky; /* for Safari */
        position: sticky;
        top: 4rem;
    // font-weight: 600;
        z-index: 2;
}

.table_sticky_header th {
    background: #ecf0f5;  
}

  .tableContainer.tableResponsive {
    overflow-x: unset;
    .table_sticky_header  {  
        top: -1px; 
    }
  }

  .tableContainer.tableResponsive .react-bootstrap-table {
    overflow: scroll;
    max-height: calc(100vh - 23rem);
    margin-bottom: 0.75rem;
  }
 
  .readOnlyCustom { 
    height: 33px;
    display: flex; 
    align-items:center;
    font-weight:600;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .react-datepicker-popper {
    z-index: 1000;
  }

  #vehicleColorOption {
    .optionListContainer {
        .option:hover {
            background: #3D8EBE;
            color: white;
        }
    }
  }
.gloomy-tag--container {
    width: 100% I !important;
}