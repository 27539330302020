
.container {
    max-width: 20rem;
    position: relative; 
}
.input {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;

}
.input:focus {
    border-color: #3c8dbc;
    box-shadow: none;
}
.input:focus-visible {
    outline: none;
    box-shadow: none;
}

.suggestionsContainer {
    position: absolute;
    background-color: white;
    z-index: 10000;
    width: 100%;
    max-height: 300px;
    overflow-y: scroll ;
}
.suggestionsContainerOpen {
    // background-color: aqua;

}
.suggestionsList{
    list-style: none;
    margin: 0;
    padding: 0;
}
.suggestion{
    margin: 2px 0px 2px 0px !important;
    display: block !important;
    padding: 4px 1em !important;
    line-height: 1.5 !important;
    font-weight: normal !important;
    
}
.suggestionFirst {
//  background-color: red;
}
.suggestionHighlighted {
    background-color: #367fa9;
    color: #ffffff;
}
.sectionContainer {

}
.sectionContainerFirst {
    
}
.sectionTitle { 
}

.autosuggestion  {
    
    position: relative; 
 
    .autoSuggestionWrap { 
        position: absolute;
        background: #ffffff;
        border: 1px solid #d2d6de;
        padding: 0.25rem 0;
        border-radius: 5px;
        width: 100%; 
        z-index: 1000;
        max-height: 10rem;
        overflow-y: scroll;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            li{
                padding: 0.25rem 1rem;
            }
            li:hover{  
                background: #3aa4dd;
                color: #ffffff;
            }
        }
    }

    .closeIcon {
        position: absolute;
        top: 0.5rem;
        right: 1rem;
    }
}