// If you want to override variables do it here
@import "variables";

*, body {
    font-size: 14px;
    font-family: 'Source Sans Pro', sans-serif;
}

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui-pro/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
//@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";
